import TIM from "tim-js-sdk";
import COS from "cos-js-sdk-v5";
import { Message } from 'element-ui';


export class beyouIm {

  constructor({ roomInfo, playInfo, groupId, userID, userSig, nick, avatar, canSay, actionCallback }) {
    console.log(groupId, userID, userSig, roomInfo, playInfo)
    this.tim = null;
    this.options = {
      SDKAppID: 1400429159, // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
    };
    this.roomInfo = roomInfo
    this.playInfo = playInfo

    this.userOptions = { nick, avatar }
    this.loginOptions = { userID, userSig };
    this.groupId = String(groupId);
    this.loginResult = null;
    this.isLogin = null

    // 页面数据
    this.memberList = []
    this.messageData = []
    this.memberInfo = {
      maxMemberCount: 0
    }
    this.isOwner = false
    this.TIMELIMIT = 120
    this.canSay = canSay
    this.actionCallback = actionCallback
    this.init();

    this.talkTime = setInterval(() => {
      this.isNeedTime += 1
    }, 1000)
    this.isNeedTime = this.TIMELIMIT

    if(!this.canSay) {
      const messageObject = {
        type: 'groupTip',
        message: `你已被禁言。`
      }
      this.canSay = false
      for (let item of this.memberList) {
        if (item.userID === userID) item.sayNothing = true
      }
      this.messageData.push(messageObject)
    }
  }

  async init() {
    console.log("init");

    this.tim = TIM.create(this.options);
    this.tim.setLogLevel(0);
    this.tim.registerPlugin({ "cos-js-sdk": COS });

    await this.login();

    this.setUserMessage()
  }
  async setUserMessage() {
    await this.waitReady()
    const result = await this.tim.updateMyProfile(this.userOptions)
    console.log('setMessage', result)
  }
  async addGroupAndGetMessage() {
    await this.waitReady()
    await this.getGroupList()
    await this.getGroupProfile()
    await this.getGroupPerson()
    await this.getMessage()
  }
  async waitReady() {
    return new Promise((resolve, reject) => {
      this.tim.on(TIM.EVENT.SDK_READY, function (event) {
        resolve()
      });
    })
  }
  updateGroupProfile(playInfo) {
    console.log('updateGroupProfile', playInfo)
    if (this.isOwner) {
      this.tim.updateGroupProfile({
        groupID: this.groupId,
        groupCustomField: [
          {
            key: 'playInfo',
            value: playInfo
          },
        ]
      }).then(res => {
        console.log('playInfo', res.data)
        res.data.group.groupCustomField.map(a => {
          if(a.key === 'playInfo') {
            console.log('playInfo', JSON.parse(a.value))

          }
        })
      })
    }
  }
  async createGroup(name) {
    await this.waitReady()
    console.log({
      type: TIM.TYPES.GRP_AVCHATROOM,
      name,
      joinOption: TIM.TYPES.JOIN_OPTIONS_FREE_ACCESS,
      groupID: this.groupId,
      groupCustomField: [
        {
          key: 'roomInfo',
          value: this.roomInfo
        },
        {
          key: 'playInfo',
          value: this.playInfo
        },
      ]
    })
    const result = await this.tim.createGroup({
      type: TIM.TYPES.GRP_AVCHATROOM,
      name,
      joinOption: TIM.TYPES.JOIN_OPTIONS_FREE_ACCESS,
      groupID: String(this.groupId),
      groupCustomField: [
        {
          key: 'roomInfo',
          value: this.roomInfo
        },
        {
          key: 'playInfo',
          value: this.playInfo
        },
      ]
    })

    console.log('createGroup', result,  this.roomInfo,this.playInfo)
    return result
  }
  async sendMessage(text) {

    console.log(text)
    let message = this.tim.createTextMessage({
      to: this.groupId,
      conversationType: TIM.TYPES.CONV_GROUP,
      payload: {
        text
      }
    });
    try {
      const messageResult = await this.tim.sendMessage(message);
      console.log('sendMessage', messageResult)
      this.setMessageData(messageResult.data.message)
    } catch ({_,code}) {
      console.log({_,code})
      if(code === 10017) {
        // 因被禁言而不能发送消息
        Message.warning('因被禁言而不能发送消息')
      }
    }
  }
  async sendStatusMessage(status) {

    let message = this.tim.createCustomMessage({
      to: this.groupId,
      conversationType: TIM.TYPES.CONV_GROUP,
      payload: {
        data: 'groupStatus',
        description: status ? 'private' : 'public',
        extension: ''
      }
    });
    const messageResult = await this.tim.sendMessage(message);
    console.log('sendStatusMessage', messageResult)
    this.setMessageData(messageResult.data.message)
  }

  async getMessage() {
    console.log('开始接受消息了')
    let _this = this
    this.tim.on(TIM.EVENT.MESSAGE_RECEIVED, function (event) {
      console.log(event)
      let msg = event.data[0]
      _this.setMessageData(msg)
    })

  }
  parseGroupTipContent(payload) {
    console.log(payload)

    switch (payload.operationType) {
      case TIM.TYPES.GRP_TIP_MBR_JOIN:
        let userName1 = payload.userIDList.map(i => this.getCurrentUser(i)).map(i => i.nick).join('、')
        this.sendDoMessage('system', `[${userName1}]加入了影厅`)
        return `[${userName1}]加入了影厅`
      case TIM.TYPES.GRP_TIP_MBR_QUIT:
        let userName2 = payload.userIDList.map(i => this.getCurrentUser(i)).map(i => i.nick).join('、')
        this.sendDoMessage('system', `[${userName2}]退出了影厅`)
        return `[${userName2}]退出了影厅`
      case TIM.TYPES.GRP_TIP_MBR_KICKED_OUT:
        this.sendDoMessage('system', `群成员：${payload.userIDList.join(',')}，被${payload.operatorID}踢出群组`)
        this.tim.deleteGroupMember({groupID: this.groupId, userIDList:[payload.userIDList.join(',')], reason: `群成员：${payload.userIDList.join(',')}，被${payload.operatorID}踢出群组`})
        console.log('payload.userIDList.join(\',\')', payload.userIDList.join(','))
        //this.memberList
        return `群成员：${payload.userIDList.join(',')}，被${payload.operatorID}踢出群组`
      case TIM.TYPES.GRP_TIP_MBR_SET_ADMIN:
        this.sendDoMessage('system', `群成员：${payload.userIDList.join(',')}，成为管理员`)
        return `群成员：${payload.userIDList.join(',')}，成为管理员`
      case TIM.TYPES.GRP_TIP_MBR_CANCELED_ADMIN:
        this.sendDoMessage('system', `群成员：${payload.userIDList.join(',')}，被撤销管理员`)
        return `群成员：${payload.userIDList.join(',')}，被撤销管理员`
      default:
        return '[群提示消息]'
    }
  }
  async setMessageData(msg) {
    console.log('setMessageData', msg)
    console.log('登录的用户id' + this.loginOptions.userID)

    console.log('获取的msg', msg, this.memberList)
    console.log(msg.type)
    let messageObject = {}
    if (msg.type === 'TIMTextElem') {

       const {avatar, nick, userID} = this.memberList.find(i => i.userID === msg.from)
        messageObject = {
          avatar, nick, userID,
          type: 'talk',
          message: msg.payload.text,
          isFormMe: userID === this.loginOptions.userID
        }


    } else if (msg.type === 'TIMGroupTipElem') {
      if (msg.payload.operationType === 2) {
        messageObject = {
          type: 'groupTip',
          message: this.parseGroupTipContent(msg.payload)
        }
        await this.getGroupProfile()
        await this.getGroupPerson()
      } else {
        await this.getGroupProfile()
        await this.getGroupPerson()

        messageObject = {
          type: 'groupTip',
          message: this.parseGroupTipContent(msg.payload)
        }
      }
    } else if (msg.type == "TIMCustomElem") {

      console.log('msg.type === "TIMCustomElem"', msg.type === "TIMCustomElem")
      const customType = msg.payload.data
      let { command, message } = JSON.parse(msg.payload.data)


      console.log(command, this.loginOptions.userID)
      if (command === "Mute_Member") {
        let { member_id } = JSON.parse(message)

        if (this.loginOptions.userID === member_id) {

          messageObject = {
            type: 'groupTip',
            message: `你已被禁言。`
          }
          this.canSay = false
        }
        for (let item of this.memberList) {
          if (item.userID === member_id) item.sayNothing = true
        }
      }
      else if (command === 'Release_Mute_Member') {
        let { member_id } = JSON.parse(message)
        if (this.loginOptions.userID === member_id) {
          messageObject = {
            type: 'groupTip',
            message: `你已被解除禁言。`
          }

          let promise = tim.setGroupMemberMuteTime({
            groupID: this.groupId,
            userID: member_id,
            muteTime: 0 // 设为0，则表示取消禁言
          });
          promise.then(function(imResponse) {
            console.log(imResponse.data.group); // 修改后的群资料
            console.log(imResponse.data.member); // 修改后的群成员资料
          }).catch(function(imError) {
            console.warn('setGroupMemberMuteTime error:', imError); // 禁言失败的相关信息
          });
          this.canSay = true
        }
        for (let item of this.memberList) {
          if (item.userID === member_id) item.sayNothing = false
        }
      }
      else if (command === 'Kick_Member') {
        let { member_id } = JSON.parse(message)
        if (this.loginOptions.userID === member_id) {
          messageObject = {
            type: 'groupTip',
            message: `你已被房主移出。`
          }
          setTimeout(() => {
            // location.href = location.protocol + '//' + location.host + '/hall'
            this.$router.replace("/hall")
          }, 1000)

          Message.error('你已被房主移出。')
        }
      }
      else if (['Movie_PAUSE', 'Movie_Play', 'Movie_Current_Time', 'Movie_Rate', 'Movie_Complete', 'Movie_Resolution'].includes(command)) {
        if(command === 'Movie_Current_Time') {
          const {Movie_Current_Time} = JSON.parse(message)
          const time = Math.ceil(Movie_Current_Time/1000)
          console.log(time)
          this.actionCallback(command,time)
        } else if(command === 'Movie_Rate') {
          const {MOVIE_RATE} = JSON.parse(message)
          this.actionCallback(command, MOVIE_RATE)
        }
        else {
          this.actionCallback(command)
        }

      }


      if (customType === 'groupStatus') {
        const statusObject = {
          'public': '影厅已切换为[公开]，任何人都可以加入哦~',
          'private': '影厅已切换为[私人]，邀请好友一起来观看吧！'
        }
        messageObject = {
          type: 'groupTip',
          message: statusObject[msg.payload.description]
        }
      }
      else if (customType === 'forbiddenWords') {
        // 三种情况，禁言者，被禁言者，无关者
        const { extension, description } = msg.payload
        const [userForDo, userForBe] = extension.split(',')
        const userForDoNick = this.getCurrentUser(userForDo).nick
        const userForBeNick = this.getCurrentUser(userForBe).nick
        if (this.loginOptions.userID === userForDo) {

          // 你禁言别人
          const object = {
            '0': `你已将[${userForBeNick}]解除禁言。`,
            '1': `你已将[${userForBeNick}]禁言。`
          }
          messageObject = {
            type: 'groupTip',
            message: object[description]
          }
          for (let item of this.memberList) {
            if (item.userID === userForBe) item.sayNothing = description === '0' ? false : true
          }
        }
        else if (this.loginOptions.userID === userForBe) {
          // 你被别人禁言
          const object = {
            '0': `你已被[${userForDoNick}]解除禁言。`,
            '1': `你已被[${userForDoNick}]禁言。`
          }
          messageObject = {
            type: 'groupTip',
            message: object[description]
          }
          this.canSay = description === '0' ? true : false

        }
        else {
          // 别人被别人禁言
          const object = {
            '0': `[${userForBeNick}]已被[${userForDoNick}]解除禁言。`,
            '1': `[${userForBeNick}]已被[${userForDoNick}]禁言。`
          }
          messageObject = {
            type: 'groupTip',
            message: object[description]
          }
        }
      }
      else if (customType == 'kickoff') {
        const { extension, description } = msg.payload
        const [userForDo, userForBe] = extension.split(',')
        const userForDoNick = this.getCurrentUser(userForDo).nick
        const userForBeNick = this.getCurrentUser(userForBe).nick

        if (this.loginOptions.userID === userForDo) {

          // 你踢别人
          messageObject = {
            type: 'groupTip',
            message: `你已将[${userForBeNick}]移除。`
          }
          await this.getGroupProfile()
          await this.getGroupPerson()

        }
        else if (this.loginOptions.userID === userForBe) {
          // 你被别人踢
          messageObject = {
            type: 'groupTip',
            message: `你已被[${userForDoNick}]移除`
          }

          Message.error('你已被房主移除。')
          setTimeout(() => {
            // location.href = location.protocol + '//' + location.host + '/hall'
            this.$router.replace("/hall")

          }, 1000)
        }
        else {
          // 别人被别人踢
          messageObject = {
            type: 'groupTip',
            message: `[${userForBeNick}]已被[${userForDoNick}]移除。`
          }
          await this.getGroupProfile()
          await this.getGroupPerson()
        }
      }
      else if (customType === 'action') {
        this.actionCallback(msg.payload)
      }
    }
    if (messageObject.message === '[群提示消息]') return;
    if (msg.type == 'TIMCustomElem' && msg.payload.data == 'action') {
      return;
    }

    this.addTalkTime()
    this.messageData.push(messageObject)
    setTimeout(() => {
      this.setTalkBoxHeight()
    }, 200)
    console.log(this.messageData)
  }
  getCurrentUser(userID) {
    return this.memberList.find(i => i.userID === userID)
  }
  setTalkBoxHeight() {
    const { scrollHeight } = document.querySelector('.hall-box-content')
    const boxHeight = 420
    if (scrollHeight > boxHeight) {
      document.querySelector('.hall-box-content').scrollTop = scrollHeight
    }
  }
  addTalkTime() {
    if (this.isNeedTime >= this.TIMELIMIT) {
      this.messageData.push({
        type: 'time',
        message: new Date().getHours() + ':' + (new Date().getMinutes() > 9 ? new Date().getMinutes() : '0' + new Date().getMinutes())
      })
      this.isNeedTime = 0
    }
  }

  async getGroupPerson() {
    const result = await this.tim.getGroupMemberList({
      groupID: this.groupId,
    });
    console.log('groupPerson', result.data.memberList)
    if (result.code === 0) {
      this.memberList = result.data.memberList

    }
    else {
      return []
    }

  }
  async getGroupProfile() {
    const result = await this.tim.getGroupProfile({
      groupID: this.groupId
    })
    console.log('groupProfile', result)
    this.memberInfo = result.data.group
    this.isOwner = result.data.group.ownerID === this.loginOptions.userID
    console.log('isOwner', this.isOwner)
    console.log('group profile', result)
    return result
  }
  async getGroupList() {
    const imResponse = await this.tim.joinGroup({
      type: TIM.TYPES.GRP_PUBLIC,
      groupID: this.groupId,
    });
    console.log("add", imResponse);

    switch (imResponse.data.status) {
      case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
        console.log(imResponse.data.group); // 加入的群组资料
        break;
      default:
        break;
    }
  }

  async setSayNothing(userID, description) {
    const result = await this.tim.setGroupMemberMuteTime({
      groupID: this.groupId,
      userID: userID,
      muteTime: description === '0' ? 0 : 100000
    })
    // ${this.loginOptions.userID},${userID}`
    let data = ''
    if(description === '1') {
      data = JSON.stringify({
        command: 'Mute_Member',
        message: JSON.stringify({
          member_id: userID
        })
      })
    }
    else {
      data = JSON.stringify({
        command: 'Release_Mute_Member',
        message: JSON.stringify({
          member_id: userID
        })
      })
    }
    let message = this.tim.createCustomMessage({
      to: this.groupId,
      conversationType: TIM.TYPES.CONV_GROUP,
      payload: {
        data,
        description: '',
        extension: ''
      }
    });
    const messageResult = await this.tim.sendMessage(message);
    console.log('sendStatusMessage', messageResult)
    await this.setMessageData(messageResult.data.message)
    await this.tim.deleteGroupMember({
      groupID: this.groupId,
      userIDList: [userID],
      reason: 'kickoff'
    })
  }
  async kickOff(userID) {
    let data = JSON.stringify({
      command: 'Kick_Member',
      message: JSON.stringify({
        member_id: userID
      })
    })
    this.memberList = this.memberList.filter(m => m.userID !== userID)
    let message = this.tim.createCustomMessage({
      to: this.groupId,
      conversationType: TIM.TYPES.CONV_GROUP,
      payload: {
        data,
        description: '',
        extension: ''
      }
    });

    const messageResult = await this.tim.sendMessage(message);
    console.log('kickoff', messageResult)
    this.setMessageData(messageResult.data.message)



  }

  async sendDoMessage(action, time) {
    console.log('sendDoMessage', action, time)
    let data = ''
    if(action === 'play') {
      let object = {
        command: 'Movie_Play'
      }
      data = JSON.stringify(object)
    }
    else if (action === 'rate') {
      let object = {
        command: 'Movie_Rate',
        message: JSON.stringify({
          MOVIE_RATE: time
        })
      }
      data = JSON.stringify(object)
    }
    else if (action === 'pause') {
      let object = {
        command: 'Movie_PAUSE'
      }
      data = JSON.stringify(object)
    }
    else if (action === 'seek') {
      let object = {
        command: 'Movie_Current_Time',
        message: JSON.stringify({
          Movie_Current_Time: time*1000
        })
      }
      data = JSON.stringify(object)
    }
    else if(action === 'system') {
      let object = {
        command: 'System_Message',
        message: time
      }
      data = JSON.stringify(object)
    }


    let message = this.tim.createCustomMessage({
      to: this.groupId,
      conversationType: TIM.TYPES.CONV_GROUP,
      payload: {
        data,
        description: '',
        extension: ''
      }
    });
    const messageResult = await this.tim.sendMessage(message);
    console.log('sendDoMessage', messageResult)
  }

  async quitGroup() {
    this.tim.quitGroup(this.groupId);
  }
  async login() {
    console.log('loginOptions', this.loginOptions)
    const result = await this.tim.login(this.loginOptions);
    console.log('login result', result.data);
    this.loginResult = result.data;
    this.checkRepeatLogin(result.data.repeatLogin);
  }
  checkRepeatLogin(repeatLogin) {
    this.isLogin = repeatLogin
  }
  async logout() {
    if (!this.isOwner) await this.tim.quitGroup(this.groupId)
    clearInterval(this.talkTime)
    // this.tim.off(TIM.EVENT.MESSAGE_RECEIVED)
    await this.tim.logout()
  }

  getMessageList() {

  }

}
